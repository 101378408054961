<template>
  <div class="box-container container fontForAll px-4" v-loading="loading">
    <div class="row mt-3">
      <!-- <div class="col-md-4 flex justify-between d-print-none">
        <div>
          <div class="text-2xl font-semibold">Chọn nội dung In</div>
          <div class="mt-3 text-xl">
            <el-radio v-model="modePrint" label="1">Phiếu Chỉ định</el-radio>
          </div>
          <div class="mt-1 text-xl">
            <el-radio v-model="modePrint" label="2">Chỉ định</el-radio>
            <div v-if="modePrint === '2'" class="mx-3 my-2">
              <el-checkbox-group v-model="checkedIndications">
                <el-checkbox
                  v-for="indication in indications"
                  :label="indication"
                  :key="indication.id + 'indication'"
                  >{{ indication.name }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <el-divider class="h-full" direction="vertical"></el-divider>
      </div>-->
      <div class="col-md-12 flex justify-center">
        <!-- phieuchidinh -->
        <div class="row text-black fontForAll mt-4" id="element-to-print" v-if="modePrint === '1'">
          <div>
            <div class="row mb-3">
              <div class="col-4 d-flex">
                <div class="inline-block align-top uppercase">
                  <div class="flex flex-col text-center" style="width: 300px">
                    <p class="mb-0 fs-17 font-bold">
                      {{
                      $globalClinic && $globalClinic.organization
                      ? $globalClinic.organization.authority_in_charge
                      : ""
                      }}
                    </p>
                    <p class="mb-0 fs-17 font-bold">
                      {{
                      $globalClinic && $globalClinic.organization
                      ? $globalClinic.organization.name
                      : ""
                      }}
                    </p>
                    <p class="mb-0 fs-17 font-bold">{{ $globalClinic ? $globalClinic.name : "" }}</p>
                  </div>
                </div>
              </div>
              <div class="col-5"></div>
              <div class="col-3">
                <div style="text-align: left">
                  <span>Mẫu số:</span>
                  <span></span>
                </div>
                <div style="text-align: left">
                  <span>PID:</span>
                  <span class="ml-1">{{ (person && person.pid) || "" }}</span>
                </div>
                <div style="text-align: left">
                  <span>Số hồ sơ:</span>
                  <span></span>
                </div>
                <div style="text-align: left">
                  <span>Số phiếu:</span>
                  <span></span>
                </div>
              </div>

              <!-- <div class="col-6 text-center">
                <p class="fs-17 font-bold mb-0">CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
                <p class="fs-17 font-bold mb-0">Độc lập - Tự do - Hạnh phúc</p>
                <p class="fs-17 font-bold mb-0">------------------------------</p>
              </div>-->
              <!-- <div class="col-3 d-flex justify-content-end text-right">
                <div>
                  <div class="text-left mr-12">
                    <p
                      class="mb-0 fs-18"
                    >Số hồ sơ: {{ indication_order && indication_order.medical_record && indication_order.medical_record.medical_record_code }}</p>
                    <p class="mb-0 fs-18 w-full whitespace-pre">PID: {{ person && person.code }}</p>
                  </div>
                </div>
              </div>-->
            </div>
            <!-- // -->
            <div class="col-12 temp-name">
              <p
                class="mb-2 text-center text-uppercase font-weight-bold fs-18"
              >PHIẾU XÉT NGHIỆM/CẬN LÂM SÀNG</p>
              <div class="flex justify-center mb-2">
                <div class="flex items-center mr-3">
                  <div class="w-3 h-3 border-box mr-1"></div>
                  <span class="fs-13 font-print">Cấp cứu</span>
                </div>
                <div class="flex items-center">
                  <div class="w-3 h-3 border-box mr-1"></div>
                  <span class="fs-13 font-print">Thường</span>
                </div>
              </div>
            </div>
            <!-- // -->
            <div class="row mb-0">
              <div class="row mb-1">
                <div class="col-8">
                  <div class="flex">
                    <p class="fs-13 mb-0">Họ tên:&nbsp;</p>
                    <p class="fs-13 mb-0 font-bold">{{ person && person.name }}</p>
                  </div>
                </div>
                <div class="col-4">
                  <div class="flex">
                    <p class="fs-13 mr-2 mb-0">Tuổi: {{ getAge() }}</p>
                    <p class="fs-13 mb-0">Giới tính: {{ genderFormat(person && person.gender) }}</p>
                  </div>
                </div>
              </div>
              <div class="flex mb-1">
                <p class="fs-13 mb-0">Địa chỉ:&nbsp;</p>
                <p class="fs-13 mb-0">{{ getAddress(person) }}</p>
              </div>
              <div class="row">
                <div class="col-8">
                  <div class="flex">
                    <p class="fs-13 mb-0">Số thẻ BHYT:&nbsp;</p>
                    <p class="fs-13 mb-0">{{ person && person.insurance_number }}</p>
                  </div>
                </div>
                <div class="col-4">
                  <p class="fs-13 mb-0">
                    Ngày hết hạn:
                    {{ formatDate(person && person.insurance_expire) }}
                  </p>
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-8">
                  <div class="flex">
                    <p class="fs-13 mb-0">Phòng:</p>
                    <p
                      class="fs-13 mb-0"
                    >{{ patientVisit && patientVisit.admission_room }}</p>
                  </div>
                </div>
                <div class="col-4">
                  <p
                    class="fs-13 mb-0"
                  >Giường: {{ patientVisit && patientVisit.admission_bed }}</p>
                </div>
              </div>-->
              <div class="flex mt-1">
                <p class="fs-13 mb-0">Chẩn đoán:&nbsp;</p>
                <p
                  v-if="patientVisit && patientVisit.icd_name"
                  class="fs-13 mb-0 content-size"
                  v-html="patientVisit && patientVisit.icd_name"
                ></p>
              </div>
              <div class="flex mt-1">
                <p class="fs-13 mb-0">Bệnh kèm theo:&nbsp;</p>
              </div>
              <div>
                <!-- <p class="fs-13 mb-3">Bệnh kèm theo:</p> -->
                <!-- //table -->
                <div>
                  <table class="table-auto border-w w-full mt-4">
                    <thead>
                      <tr class="text-center">
                        <th class="fs-13 border-w">STT</th>
                        <th class="fs-13 border-w">TÊN CHỈ ĐỊNH</th>
                        <th class="fs-13 border-w">ĐƠN VỊ THỰC HIỆN</th>
                        <th class="fs-13 border-w">ĐVT</th>
                        <th class="fs-13 border-w">Chỉ số BT</th>
                        <th class="fs-13 border-w">Kết quả</th>
                      </tr>
                    </thead>
                    <tbody v-for="(group, index) in indications" :key="group.id">
                      <!-- <tr>
                        <td colspan="2" class="border-w fs-13">
                          <span class="ml-1 italic font-bold font-print">{{
                            group.name
                          }}</span>
                        </td>
                        <td></td>
                      </tr>-->
                      <tr>
                        <td class="fs-13 border-w text-center font-print">{{ index + 1 }}</td>
                        <td class="fs-13 border-w margin-12">
                          <span class="ml-1 font-print">{{ group.name }}</span>
                        </td>
                        <td class="fs-13 border-w">
                          <span class="ml-1 font-print">
                            {{
                            indication_order &&
                            indication_order.service_provider &&
                            indication_order.service_provider.name
                            }}
                          </span>
                        </td>
                        <td class="fs-13 border-w margin-12">
                          <span class="ml-1 font-print"></span>
                        </td>
                        <td class="fs-13 border-w margin-12">
                          <span class="ml-1 font-print"></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- // -->
            <div class="flex justify-between">
              <div>
                <!-- <p class="italic fs-13 mt-1 font-bold">Hướng dẫn: </p> -->
              </div>
              <div class="text-center mt-3">
                <p class="mb-0 fs-13">{{ getHMDMYVN(date) }}</p>
                <p class="mb-5 fs-13 font-bold">BÁC SĨ ĐIỀU TRỊ</p>
                <p class="fs-13">
                  {{
                  indication_order &&
                  indication_order.sign &&
                  indication_order.sign.user &&
                  indication_order.sign.user.name
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- chidinh -->
        <div class="row text-black fontForAll mt-4" id="element-to-print" v-if="modePrint === '2'">
          <div class="h-item-scroll">
            <div v-for="group in checkedIndications" :key="group.id">
              <div>
                <div class="row mb-3">
                  <div class="col-4 d-flex text-center">
                    <div class="inline-block align-top uppercase">
                      <div class="flex flex-col" style="width: 300px">
                        <p class="mb-0 fs-12 font-bold uppercase">
                          {{
                          $globalClinic && $globalClinic.organization
                          ? $globalClinic.organization.authority_in_charge
                          : ""
                          }}
                        </p>
                        <p class="mb-0 fs-12 font-bold uppercase">
                          {{
                          $globalClinic && $globalClinic.organization
                          ? $globalClinic.organization.name
                          : ""
                          }}
                        </p>
                        <p
                          class="mb-0 font-bold fs-13"
                        >{{ $globalClinic ? $globalClinic.name : "" }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-5"></div>
                  <div class="col-3">
                    <div style="text-align: left">
                      <span>Mẫu số:</span>
                      <span></span>
                    </div>
                    <div style="text-align: left">
                      <span>PID:</span>
                      <span>{{ (form.person && form.person.code) || "" }}</span>
                    </div>
                    <div style="text-align: left">
                      <span>Số hồ sơ:</span>
                      <span></span>
                    </div>
                    <div style="text-align: left">
                      <span>Số phiếu:</span>
                      <span></span>
                    </div>
                  </div>

                  <!-- <div class="col-6 text-center">
                    <p class="fs-17 font-bold mb-0">
                      CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM
                    </p>
                    <p class="fs-17 font-bold mb-0">
                      Độc lập - Tự do - Hạnh phúc
                    </p>
                    <p class="fs-17 font-bold mb-0">
                      ------------------------------
                    </p>
                  </div>-->
                  <!-- <div class="col-3 flex justify-end">
                    <div class="text-left mr-12">
                      <div>
                        <p
                          class="mb-0 fs-13"
                        >Số hồ sơ: {{ indication_order && indication_order.medical_record && indication_order.medical_record.medical_record_code }}</p>
                        <p class="mb-0 fs-13">PID: {{ person && person.code }}</p>
                      </div>
                    </div>
                  </div>-->
                </div>
                <!-- // -->
                <div class="col-12 temp-name">
                  <p
                    class="mb-2 text-center text-uppercase font-weight-bold fs-18"
                  >PHIẾU XÉT NGHIỆM/CẬN LÂM SÀNG</p>
                  <div class="flex justify-center mb-2">
                    <div class="flex items-center mr-3">
                      <div class="w-3 h-3 border-box mr-1"></div>
                      <span class="fs-13 font-print">Cấp cứu</span>
                    </div>
                    <div class="flex items-center">
                      <div class="w-3 h-3 border-box mr-1"></div>
                      <span class="fs-13 font-print">Thường</span>
                    </div>
                  </div>
                </div>
                <!-- // -->
                <div class="row mb-0">
                  <div class="row mb-1">
                    <div class="col-8">
                      <div class="flex">
                        <p class="fs-13 mb-0">Họ tên:&nbsp;</p>
                        <p class="fs-13 mb-0 font-bold">{{ person && person.name }}</p>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="flex">
                        <p class="fs-13 mr-2 mb-0">Tuổi: {{ getAge() }}</p>
                        <p class="fs-13 mb-0">Giới tính: {{ genderFormat(person && person.gender) }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="flex mb-1">
                    <p class="fs-13 mb-0">Địa chỉ:&nbsp;</p>
                    <p class="fs-13 mb-0">{{ getAddress(person) }}</p>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <div class="flex">
                        <p class="fs-13 mb-0">Số thẻ BHYT:&nbsp;</p>
                        <p class="fs-13 mb-0">{{ person && person.insurance_number }}</p>
                      </div>
                    </div>
                    <div class="col-4">
                      <p class="fs-13 mb-0">
                        Ngày hết hạn:
                        {{ formatDate(person && person.insurance_expire) }}
                      </p>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-8">
                      <div class="flex">
                        <p class="fs-13 w-20 mb-0">Phòng:</p>
                        <p
                          class="fs-13 mb-0"
                        >{{ patientVisit && patientVisit.admission_room }}</p>
                      </div>
                    </div>
                    <div class="col-4">
                      <p
                        class="fs-13 mb-0"
                      >Giường: {{ patientVisit && patientVisit.admission_bed }}</p>
                    </div>
                  </div>-->
                  <div class="flex mt-1">
                    <p class="fs-13 mb-0">Chẩn đoán:</p>
                    <p
                      v-if="patientVisit && patientVisit.icd_name"
                      class="fs-13 mb-0 content-size"
                      v-html="patientVisit && patientVisit.icd_name"
                    ></p>
                  </div>
                  <div class="flex mt-1">
                    <p class="fs-13 mb-0">Bệnh kèm theo:&nbsp;</p>
                  </div>
                  <div>
                    <!-- <p class="fs-13 mb-3">Bệnh kèm theo:</p> -->
                    <div>
                      <table class="table-auto w-100 border-w mt-4">
                        <thead>
                          <tr class="text-center">
                            <th class="fs-13 border-w">STT</th>
                            <th class="fs-13 border-w">TÊN CHỈ ĐỊNH</th>
                            <th class="fs-13 border-w">ĐƠN VỊ THỰC HIỆN</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="2" class="border-w fs-13">
                              <span class="ml-1 italic font-bold font-print">
                                {{
                                group.name
                                }}
                              </span>
                            </td>
                            <td></td>
                          </tr>
                          <tr v-for="(item, i) in group.categories" :key="i">
                            <td class="fs-13 border-w text-center font-print">{{ i + 1 }}</td>
                            <td class="fs-13 border-w margin-12">
                              <span class="ml-1 font-print">
                                {{
                                item.name
                                }}
                              </span>
                            </td>
                            <td class="fs-13 border-w">
                              <span class="ml-1 font-print">
                                {{
                                getDataDemo(item.target_id)
                                }}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- // -->
                <div class="flex justify-between">
                  <div>
                    <!-- <p class="italic fs-13 mt-1 font-bold">Hướng dẫn: </p> -->
                  </div>
                  <div class="text-center mt-3">
                    <p class="mb-0 fs-13">{{ getHMDMYVN(date) }}</p>
                    <p class="mb-5 fs-13 font-bold">BÁC SĨ ĐIỀU TRỊ</p>
                    <p class="fs-13">{{ indication_order && indication_order.signer_name }}</p>
                  </div>
                </div>
              </div>
              <!-- <div style="break-after:page"></div>
              <div class="border-b-space print:hidden mt-72 mb-5"></div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="print:hidden">
      <div class="d-flex justify-content-end mt-4">
        <div>
          <el-button class="mr-3" @click="back">Quay lại</el-button>
          <button class="btn bg-pri text-white" @click="print">In Phiếu</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JsBarcode from 'jsbarcode'
import moment from 'moment'
import dataDemo from './ModalIndication/dataMemo'
import { mixinGetPersonInfo } from '@/utils/mixinGetPersonInfo'

export default {
  name: 'CaseIndicationsPrinter',
  mixins: [mixinGetPersonInfo],
  data () {
    return {
      patientVisit: {},
      itemSelected: [],
      person: null,
      indication_order: null,
      indications: [],
      checkedIndications: [],
      loading: false,
      modePrint: '1',
      date: new Date(),
      demo: dataDemo,
      medical_record: null,
      treat_doctor: null,
      form: 1
    }
  },
  mounted () {
    this.getIndicationOderDetail()
  },
  watch: {
    modePrint (value) {
      if (value === '2') this.checkedIndications = this.indications
      else this.checkedIndications = []
    },
    medical_record () {
      this.generateBarcode()
    },
    checkedIndications () {
      this.generateBarcode()
    }
  },
  methods: {
    generateBarcode () {
      if (this.medical_record?.medical_record_code) {
        this.$nextTick(() => {
          JsBarcode('#barcode', this.medical_record.medical_record_code, {
            fontSize: 0
          })
        })
      }
    },
    formatDate (date) {
      if (!date) return
      return window.moment(date).format('DD-MM-YYYY')
    },
    getDataDemo (id) {
      return id ? this.demo[id - 1].name : ''
    },
    genderFormat (gender) {
      if (gender === 1) {
        return 'Nam'
      }

      if (gender === 2) {
        return 'Nữ'
      }

      return 'Other'
    },
    getHMDMYVN (date_time) {
      let dt = window.moment(date_time)
      let hm = dt.clone().format('HH:mm')
      let d = dt.clone().format('DD')
      let m = dt.clone().format('MM')
      let y = dt.clone().format('YYYY')
      return hm + ', Ngày ' + d + ' tháng ' + m + ' năm ' + y
    },
    async getIndicationOderDetail () {
      if (!this.$route.params.id) {
        this.indication_order = null
        return
      }
      this.selected = []
      this.deleted = []
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getIndicationPrintV2(this.$route.params.id)
        .then(async r => {
          this.indication_order = r.data
          this.treat_doctor = r.data?.treat_doctor
          this.medical_record = r.data?.medical_record
          this.person = r.data?.person
            ? r.data?.person
            : r.data?.medical_record?.person
              ? r.data?.medical_record?.person
              : r.data?.health_record?.person
                ? r.data?.health_record?.person
                : {}
          this.indications = r.data?.indication
          await this.getPatientVisit(r.data?.patient_visit_id)
        })
        .finally(() => {
          this.loading = false
        })
    },
    print () {
      document.title = `Phieu chi dinh_${moment().format('DDMMYYYY')}.pdf`
      window.print()
    },
    back () {
      this.$router.go(-1)
    },
    async getPatientVisit (id) {
      const self = this
      if (!id) return
      self.loading = true

      try {
        const response = await self.$rf
          .getRequest('DoctorRequest')
          .getDetailPatientVisit(id)
        self.patientVisit = response.data || {}
      } catch (error) {
        console.log('', error)
      } finally {
        self.loading = false
      }
    },
    getAge () {
      return (
        this.person?.birthday &&
        (window.moment().diff(this.person?.birthday, 'years', false)
          ? window.moment().diff(this.person?.birthday, 'years', false)
          : '')
      )
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .box-container {
    color: black;
    margin: auto;
    ::v-deep * {
      font-family: 'Times New Roman', 'Times', 'Tinos', serif !important;
    }
  }
  .el-checkbox {
    display: block;
  }
  .margin-12 {
    margin-left: 20px;
  }

  .fontForAll * {
    font-family: 'Times New Roman', 'Tinos', Times, serif !important;
    font-size: 14px;
  }

  .height-48 {
    height: 48px;
  }
  .border-b-space {
    border-bottom: 1px solid black;
  }
  .border-box {
    border: 1px solid black;
  }
  // @media screen {
  //   .h-item-scroll {
  //     overflow-y: auto;
  //     height: 80vh;
  //   }
  // }
  .border-w {
    border: 1px solid black;
  }
  p,
  th {
    font-family: 'Tinos', serif;
  }
  .font-print {
    font-family: 'Tinos', serif;
  }
}

@media print {
  @page {
    size: portrait;
    margin: 0;
  }

  #BrowserPrintDefaults {
    display: none;
  }

  html,
  body {
    .fontForAll * {
      font-family: 'Times New Roman', 'Tinos', Times, serif !important;
    }
    overflow: hidden;
  }

  .container {
    max-width: unset;
  }
  p.bodyText {
    display: none;
  }
}
</style>
